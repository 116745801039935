import { render, staticRenderFns } from "./subscriptionOverview.vue?vue&type=template&id=11180b9a&scoped=true&"
import script from "./subscriptionOverview.vue?vue&type=script&lang=js&"
export * from "./subscriptionOverview.vue?vue&type=script&lang=js&"
import style0 from "./subscriptionOverview.vue?vue&type=style&index=0&id=11180b9a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11180b9a",
  null
  
)

/* custom blocks */
import block0 from "./subscriptionOverview.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports