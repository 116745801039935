<template>
  <base-dropdown max-height="345">
    <base-button
      slot="toggle"
      color="white"
      class="w-full flex"
      :justify="'justify-start'"
    >
      <div v-if="!isSelectOnly && selectSpace">
        <div v-if="!isSelectOne">
          {{ $t('workspace') }}
          <span v-if="selectSpace.length > 0" class="tag">{{
            selectSpace.length
          }}</span>
          <base-icon :width="16"> <icon-chevron-down /></base-icon>
        </div>
        <div v-else>
          <div
            v-if="selectSpace.length != 0"
            class="flex"
            style="max-width: 200px"
          >
            <img :src="selectSpace.imageLogo" class="w-6 h-6 mr-2" />
            <span class="flex-1 text-black truncate mr-2">{{
              selectSpace.name
            }}</span>
            <base-icon :width="16"> <icon-chevron-down /></base-icon>
          </div>
          <div v-else>
            {{ $t('workspace') }}
            <base-icon :width="16"> <icon-chevron-down /></base-icon>
          </div>
        </div>
      </div>
      <div v-else>
        <span> เลือก...</span>
        <base-icon :width="16" class="absolute right-0 mr-4">
          <icon-chevron-down />
        </base-icon>
      </div>
    </base-button>
    <div v-if="!isSelectOnly" class="p-4">
      <div class="flex mb-2">
        <p class="font-medium">{{ $t('workspace') }}</p>
      </div>
      <base-input-text
        v-model="search"
        inner-class="base-input--gray"
        :placeholder="$t('search')"
        icon-fa="search"
      />
    </div>
    <v-scroller style="max-height:200px" @bottom="scrollBottom">
      <base-dropdown-item
        v-for="(workspace, index) in workspaces"
        :key="index"
        keep
        @click="
          isSelectOne
            ? toggleWorkspaceOne(workspace.team || workspace)
            : toggleActiveWorkspace(workspace.team || workspace)
        "
      >
        <div class="flex items-center overflow-hidden">
          <base-checkbox
            :checked="isWorkspaceActive(workspace.team || workspace)"
          />
          <div
            class="rounded-full flex-1 px-2 py-1  text-white font-medium truncate"
          >
            <div class="flex">
              <img
                :src="workspace.imageLogo || workspace.team.imageLogo"
                class="w-6 h-6 mr-2"
              />
              <span class="flex-1 text-black truncate">{{
                workspace.name || workspace.team.name
              }}</span>
            </div>
          </div>
        </div>
        <!--   <div class="text-center text-gray-500 text-sm p-2">
        No workspace
      </div>-->
      </base-dropdown-item>
    </v-scroller>
  </base-dropdown>
</template>
<script>
import IconChevronDown from '@/components/icons/IconChevronDown';
import { mapGetters } from 'vuex';
import apis from '@/services/apis';
import VScroller from '@/components/scroller.vue';

export default {
  components: {
    IconChevronDown,
    VScroller
  },
  props: {
    isSelectOnly: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    workspaceList: {
      type: Array,
      default: () => []
    },
    isSelectOne: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false,
      queryText: '',
      selectSpace: this.selected,
      search: '',
      teamIdUserSelected: [],
      workspaces: []
    };
  },
  watch: {
    selected(val) {
      this.selectSpace = val;
    },
    getMemberRemove(val) {
      this.teamIdUserSelected = [];
      if (val?.teams?.length > 0) {
        val?.teams.forEach(element => this.teamIdUserSelected.push(element.id));
      }
    },
    getTeams() {
      this.updateWorkspace();
      this.getWorkspace();
    }
  },
  async mounted() {
    await this.$store.dispatch('auth/fetchMe');
    this.updateWorkspace();
    this.getWorkspace();
  },
  computed: {
    ...mapGetters({
      getTeams: 'teams/getTeams',
      getMemberRemove: 'subscription/getMemberRemove',
      nextPage: 'teams/getNextPage'
    }),

    lastWorkspace() {
      return this.$store.getters['auth/getUser'].lastVisitWorkSpaceID;
    }
  },
  methods: {
    updateWorkspace() {
      if (this.lastWorkspace && this.isSelectOne) {
        this.selectSpace = this.getTeams.filter(
          f => f.id === this.lastWorkspace
        )[0];
      }
      this.$emit('input', this.selectSpace);
    },
    isWorkspaceActive(space) {
      return !this.isSelectOne
        ? this.selectSpace.some(spaceId => spaceId === space.id)
        : space.id === this.selectSpace.id;
    },
    toggleActiveWorkspace(space) {
      const value = this.isWorkspaceActive(space)
        ? this.selectSpace.filter(spaceId => spaceId !== space.id)
        : [...this.selectSpace, space.id];
      this.selectSpace = value;
      this.$emit('input', value);
    },
    toggleWorkspaceOne(space) {
      this.selectSpace === space
        ? (this.selectSpace = [])
        : (this.selectSpace = space);
      this.$emit('input', this.selectSpace);
    },
    getWorkspace() {
      const teamAll = this.getTeams;
      const workspacesFilter = teamAll.filter(
        f => f.name.toLowerCase().search(this.search.toLowerCase()) >= 0
      );
      this.workspaces =
        this.workspaceList.length > 0 ? this.workspaceList : workspacesFilter;
    },
    scrollBottom() {
      if (this.nextPage) {
        this.$store.dispatch('teams/fetchTeamsNext');
      }
    }
  }
};
</script>
<style>
.select-board-report--popover {
  @apply relative overflow-y-auto;
  max-height: 383px;
  width: 461px;
}
</style>
<style scoped>
.tag {
  @apply text-sm px-2 rounded-3xl bg-brand-blue text-white mx-2;
}
</style>
<i18n>
{
  "en": {
    "workspace": "Workspace",
    "search": "Search"
  },
  "th": {
    "workspace": "เวิร์คสเปซ",
    "search": "ค้นหา"
  }
}
</i18n>
