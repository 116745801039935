<template>
  <div>
    <p v-if="showFilter" class="text-2xl mb-4">
      {{ $t('subscription.workspace_management') }}
    </p>
    <div class="flex mb-8">
      <p class="text-lg">{{ $t('overview') }}</p>
      <div class="ml-auto cursor-pointer flex">
        <select-workspace
          v-if="showFilter && getTeams.length"
          class="pr-4 h-full"
          is-select-one
          @input="selectedSpace"
        />
        <base-button
          v-for="(day, index) in filterDay"
          :key="index"
          style="margin-right: 0"
          :color="!day.selected ? 'transparent' : 'brand'"
          :rounded="null"
          :class="{
            'rounded-tl rounded-bl': index == 0,
            'rounded-tr rounded-br': index == 2
          }"
          @click="selectFilter(index)"
          >{{ $t(`filter_day.${day.lable}`) }}</base-button
        >
        <report-due-date
          :color="isSelectDueDate ? 'brand-blue' : 'white'"
          class="ml-2 self-center"
          :class="{ 'bg-brand-blue rounded': isSelectDueDate }"
          :default-date-this-week-calendar="defaultDateThisWeekCalendar"
          :select-date-from-team="3"
          :is-overview="true"
          @datePick="range"
        />
      </div>
    </div>
    <dashboardSummary
      class="mb-8"
      :is-overview="true"
      :summary="summaryOveiview"
    />
    <div class="grid grid-cols-2 gap-x-4">
      <base-box>
        <div slot="header">
          {{ $t('board_overview') }}

          <span v-if="getSummaryOverview.totalBoards"
            >({{ getSummaryOverview.totalBoards }} {{ $t('board') }})</span
          >
        </div>
        <div v-if="getSummaryOverview.totalTasks" class="grid grid-cols-2">
          <div class="relative p-6">
            <chart-doughnut
              :chart-data="chartData"
              :options="chartOptions"
              text-center-with-description
              font-weight-semi-bold
            />
            <div class="text__chart">
              <p style="font-size:2.5vw;">
                {{ getSummaryOverview.totalTasks }}
              </p>
              <p style="font-size:1vw;">{{ $t('all_task') }}</p>
            </div>
          </div>
          <div>
            <subscriptionListAllBoard :board-length="4" />
            <div
              class="cursor-pointer text-brand-blue text-sm"
              @click="opedAllBord"
            >
              {{ $t('view_all_task') }}
            </div>
          </div>
        </div>
        <div v-else class="text-center py-6">
          <base-icon size="155">
            <icon-empty-overview-board />
          </base-icon>
          <div>
            <p class="mb-2">{{ $t('empty_board.title') }}</p>
            <div
              class="text-sm text-gray-caption"
              v-html="$t('empty_board.description')"
            />
          </div>
        </div>
      </base-box>
      <dashboard-insight
        :is-overview="true"
        :insight="insightBoard"
        style="height: fit-content;"
      />
    </div>
    <modal ref="all-bord" name="all-bord" height="auto">
      <subscriptionListAllBoard :is-modal="true" />
    </modal>
  </div>
</template>
<script>
import dashboardSummary from '@/components/dashboard/dashboard-summary';
import dashboardInsight from '@/components/dashboard/dashboard-insight';
import reportDueDate from '@/components/report/report-due-date';
import selectWorkspace from '@/components/select/select-workspace';
import moment from 'moment';
import chartDoughnut from '@/components/chart/chart-doughnut';
import subscriptionListAllBoard from '@/components/subscription/subscription-list-all-board';
import modalSubscriptionExpired from '@/components/modal/modal-subscription-expired';
import { mapGetters } from 'vuex';
import IconEmptyOverviewBoard from '@/components/icons/IconEmptyOverviewBoard';
export default {
  props: {
    showFilter: {
      type: Boolean,
      default: true
    }
  },
  components: {
    dashboardSummary,
    dashboardInsight,
    reportDueDate,
    selectWorkspace,
    chartDoughnut,
    subscriptionListAllBoard,
    IconEmptyOverviewBoard
  },
  data() {
    return {
      filterDay: [
        {
          lable: 'last_seven',
          day: 7,
          selected: true
        },
        {
          lable: 'last_fifteen',
          day: 15,
          selected: false
        },
        {
          lable: 'last_ninety',
          day: 90,
          selected: false
        }
      ],
      date: { dateRange: [] },
      spaceSelected: '',
      boarsData: {
        boardColor: [],
        boardTotalTask: [],
        boardName: []
      },
      summaryOveiview: {
        allboard: 0,
        inprogress: 0,
        done: 0,
        overdue: 0
      },
      isSelectDueDate: false
    };
  },
  computed: {
    ...mapGetters('subscription', ['getSummaryOverview', 'getCurrentPlan']),
    ...mapGetters('teams', ['getTeams']),
    defaultDateThisWeekCalendar() {
      return {
        start: moment()
          .startOf('week')
          .toDate(),
        end: moment()
          .endOf('week')
          .toDate()
      };
    },
    chartOptions() {
      return {
        cutoutPercentage: 85,
        legend: {
          display: false
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        }
      };
    },
    chartData() {
      return {
        datasets: [
          {
            label: 'My First Dataset',
            data: this.boarsData.boardTotalTask,
            backgroundColor: this.boarsData.boardColor,
            hoverOffset: 4
          }
        ],
        labels: this.boarsData.boardName,
        summary: {}
      };
    },
    insightBoard() {
      const data = {
        complete: this.getSummaryOverview.totalCompletedTasks,
        inprogress: this.getSummaryOverview.totalIncompletedTasks,
        total: this.getSummaryOverview.totalTasks
      };
      return data;
    }
  },
  watch: {
    spaceSelected() {
      this.getDashboard();
    },
    getSummaryOverview(val) {
      this.boarsData = {
        boardColor: [],
        boardTotalTask: [],
        boardName: []
      };
      val.boards?.forEach(board => {
        this.boarsData.boardColor.push(board.boardColor);
        this.boarsData.boardTotalTask.push(board.totalTasks);
        this.boarsData.boardName.push(board.boardName);
      });

      (this.summaryOveiview.allboard = val.totalBoards || 0),
        (this.summaryOveiview.inprogress = val.totalIncompletedTasks || 0),
        (this.summaryOveiview.done = val.totalCompletedTasks || 0),
        (this.summaryOveiview.overdue = val.totalOverDueTasks || 0);
    },
    $route(router) {
      this.overviewTeamId();
    }
  },

  async mounted() {
    await this.$store.dispatch('subscription/clearDashboardOverview');
    await this.$store.dispatch('subscription/fetchCurrentPlan');
    this.overviewTeamId();
  },
  methods: {
    range(date) {
      this.filterDay.map(filter => (filter.selected = false));
      this.isSelectDueDate = true;
      const lastDay = moment(date.dateRange.start).subtract(1, 'day');
      date.dateRange.start = moment(lastDay).format('YYYY-MM-DD[T17:00:00Z]');
      date.dateRange.end = moment(date.dateRange.end).format(
        'YYYY-MM-DD[T16:59:59Z]'
      );
      this.date = date;
      this.getDashboard();
    },
    selectFilter(select) {
      this.filterDay.map(select => (select.selected = false));
      this.filterDay[select].selected = true;
      this.isSelectDueDate = false;
      this.getDashboard();
    },
    opedAllBord() {
      this.$modal.show('all-bord');
    },
    getDashboard() {
      const selectedFilter = this.filterDay.filter(
        filter => filter.selected == true
      );
      const date =
        this.date.dateRange.length == 0
          ? this.defaultDateThisWeekCalendar
          : this.date.dateRange;

      const today = moment();
      const startDate = selectedFilter.length
        ? moment().subtract(selectedFilter[0].day, 'days')
        : date.start;

      const endDate = selectedFilter.length ? today : date.end;

      const teamId =
        typeof this.spaceSelected == 'object'
          ? this.spaceSelected.id
          : this.spaceSelected;

      const selected = {
        endAt: moment(endDate).format('YYYY-MM-DD[T16:59:59Z]'),
        startAt: moment(startDate).format('YYYY-MM-DD[T16:59:59Z]'),
        teamID: teamId
      };
      teamId
        ? this.$store.dispatch('subscription/fetchDashboard', selected)
        : this.$store.dispatch('subscription/clearDashboardOverview');
    },
    selectedSpace(e) {
      this.spaceSelected = e;
    },
    overviewTeamId() {
      if (this.$route.name == 'Teams-Dashboard') {
        this.spaceSelected = [];
        this.spaceSelected = parseInt(this.$route.params.id);
      }
    }
  }
};
</script>

<style scoped>
.text__chart {
  @apply absolute text-center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<i18n>
{
  "en": {
    "header":"Workspace Management",
    "overview": "Overview",
    "filter_day":{
      "last_seven": "Last 7 days",
      "last_fifteen": "Last 15 days",
      "last_ninety": "Last 90 days"
    },
    "board_overview": "Board overview",
    "board": "boards",
    "view_all_task": "View all",
    "all_task": "All tasks",
    "empty_board":{
      "title": "Data Not Found",
      "description": "Summary of overall board activity. This function requires board information and other data to be displayed.<br/> Please create a board to view your board summary."
    }
  },
  "th": {
    "header":"ระบบจัดการเวิร์คสเปซ",
    "overview": "ภาพรวม",
    "filter_day":{
      "last_seven": "ย้อนหลัง 7 วัน",
      "last_fifteen": "ย้อนหลัง 15 วัน",
      "last_ninety": "ย้อนหลัง 90 วัน"
    },
    "board_overview": "ภาพรวมบอร์ด",
     "board": "บอร์ด",
     "view_all_task": "ดูทั้งหมด",
     "all_task": "งานทั้งหมด",
     "empty_board":{
      "title": "ไม่พบข้อมูล",
      "description": "การแสดงผลรวมของบอร์ดในเวิร์คสเปซ ต้องมีข้อมูลบอร์ดและงานต่างๆ<br/>โปรดสร้างบอร์ดเพื่อดูข้อมูลผลรวม"
    }
  }
}
</i18n>
