<template>
  <div class="mt-10">
    <div v-if="isModal" class="p-4 border-b-2">
      <p class="text-lg">ภาพรวมในบอร์ดเวิร์คสเปซ</p>
      <base-button
        class="absolute top-0 right-0 mt-3"
        icon="close"
        icon-size="18"
        color="text"
        @click="$modal.hide('all-bord')"
      />
    </div>

    <div :class="{ 'p-4': isModal }">
      <p v-if="isModal">
        บอร์ดทั้งหมด ( บอร์ด)
      </p>
      <table class="w-full my-3">
        <thead
          class="text-gray-caption text-sm uppercase"
          :class="{ 'border-b-2': !isModal }"
        >
          <tr class="font-normal font-normal">
            <th class="text-left text-xs" :class="{ 'pl-4': isModal }">
              {{ $t('header.board') }}
            </th>
            <th
              class="text-right text-xs whitespace-nowrap"
              :class="{ 'pr-4': isModal }"
            >
              {{ $t('header.task') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(board, index) in getSummaryOverview.boards"
            :key="index"
            class="p-4 rounded text-sm"
            :class="{ 'border-2': isModal }"
          >
            <td
              v-if="index < boardLength"
              :class="{ 'py-4 pl-4': isModal }"
              class="my-2 flex items-center"
            >
              <span
                class="dot-board"
                :style="{ background: board.boardColor }"
              />
              <v-clamp class="text-sm font-medium break-all" :max-lines="1">
                {{ board.boardName }}
              </v-clamp>
            </td>
            <td
              v-if="index < boardLength"
              class="text-right"
              :class="{ 'pr-4': isModal }"
            >
              {{ board.totalTasks }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VClamp from 'vue-clamp';

export default {
  components: {
    VClamp
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    boardLength: {
      type: Number,
      default: 100
    }
  },
  computed: {
    ...mapGetters('subscription', ['getSummaryOverview'])
  }
};
</script>

<style>
.dot-board {
  @apply mr-1;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
</style>

<i18n>
{
  "en": {
    "header":{
      "board": "Name",
      "task": "Tasks"
    }
  },
  "th": {
  "header":{
      "board": "ชื่อบอร์ด",
      "task": "จำนวนงาน"
    }
  }
}
</i18n>
